import React, {Component} from 'react';

import "./CreditCardForm.scss"
import StripeInlineCardForm from "./StripeInlineCardForm";

class InlineCreditCardForm extends Component {
    constructor(props) {
        super(props);
        this.inlineFieldsFormRef = React.createRef();
    }

    render() {
        return (
            <StripeInlineCardForm
                childRef={ref => (this.inlineFieldsFormRef = ref)}
                handleResult={this.props.handleResult}
                changeCardBrand={this.props.changeCardBrand}
                onPayModeChange={this.props.onPayModeChange}
                isCC={this.props.isCC}
                isUpgrade={this.props.isUpgrade}
                stripeKey={this.props.stripeKey}
                handleAppLoader={this.props.handleAppLoader}
                handlePaymentFormIsValid={this.props.handlePaymentFormIsValid}
                setupIntent={this.props.setupIntent}
                isSingleExport={this.props.isSingleExport}
                paymentMethodUpdate={this.props.paymentMethodUpdate}
                handleUpdatePaymentMethod={this.props.handleUpdatePaymentMethod}
            />
        );
    }
}

export default InlineCreditCardForm;