
export const RESPONSIVE_MOBILE_RESOLUTION = 768;
export const RESPONSIVE_TABLET_RESOLUTION = 1024;

export const INSTANCE_NAME = "WeVideo";
export const APP_VERSION = "kungfu";
export const HUB_VERSION = "2.1.0";
export const WEVIDEO_TESTING_URI = '';
export const CHROME_RECORDER_APP = "chrome_recorder";
export const CHURNKEY_APP_ID = "fwxhdfhci";

export const APP_HASH_PATH_ROUTE = '/cart';
export const APP_HASH_SYMBOL = "#";
export const APP_HASH_PATH_ROUTE_INDEX = APP_HASH_PATH_ROUTE + APP_HASH_SYMBOL;

//!Important: dont use "_" in the APP_HASH_PATH for the strings
export const APP_HASH_PATH = {
    SIGN_UP: 'signup',
    AUTH_PAGE: 'auth',
    PLANS_PAGE: 'plans',
    CHECKOUT_PAGE: 'checkout',
    CHECKOUT_PRIVACY_PAGE: 'checkout',
    PAYMENT_COMPLETED: 'payment-completed',
    PAYPAL_COMPLETED: 'paypalCompleted',
    FREE_ACCOUNT_COMPLETED: 'free-account-completed',
    EDU_TRIAL_ACCOUNT_COMPLETED: 'edutrial-account-completed',
    BIZ_TRIAL_ACCOUNT_COMPLETED: 'biztrial-account-completed',
    EMAIL_ACTIVATION: 'email-activation',
    ADDITIONAL_SEATS: 'additional-seats',
    ESSENTIALS: 'essentials',
    CANCEL_ESSENTIALS: 'cancel-essentials',
    CANCEL_TRIAL: 'cancel-trial',
    UPGRADES_DISABLED: 'upgrades-disabled',
};

export const HASH_SUB_PAGE_DELIMITER = '_';

export const CHECKOUT_PAGE_TYPE = {
    CHECKOUT_SUMMARY: HASH_SUB_PAGE_DELIMITER + 'summary',
    CHECKOUT_SINGLE_EXPORT: HASH_SUB_PAGE_DELIMITER + 'single_export',
};

export const APP_HASH_ROUTES = {
    SIGN_UP: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.SIGN_UP,
    AUTH_PAGE: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.AUTH_PAGE,
    PLANS_PAGE: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.PLANS_PAGE,
    CHECKOUT_PAGE: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.CHECKOUT_PAGE + CHECKOUT_PAGE_TYPE.CHECKOUT_SUMMARY,
    CHECKOUT_SINGLE_EXPORT: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.CHECKOUT_PAGE + CHECKOUT_PAGE_TYPE.CHECKOUT_SINGLE_EXPORT,
    PAYMENT_COMPLETED: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.PAYMENT_COMPLETED,

    FREE_ACCOUNT_COMPLETED: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.FREE_ACCOUNT_COMPLETED,
    EDU_TRIAL_ACCOUNT_COMPLETED: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.EDU_TRIAL_ACCOUNT_COMPLETED,
    BIZ_TRIAL_ACCOUNT_COMPLETED: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.BIZ_TRIAL_ACCOUNT_COMPLETED,
    EMAIL_ACTIVATION: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.EMAIL_ACTIVATION,
    UPGRADES_DISABLED: APP_HASH_PATH_ROUTE_INDEX + APP_HASH_PATH.UPGRADES_DISABLED
};

export const APP_BROWSER_ROUTES = {
    CHECKOUT_SUMMARY_PAGE: APP_HASH_PATH_ROUTE + '/checkout_summary',
    INVALID_EMAIL_PAGE: APP_HASH_PATH_ROUTE + '/invalid-email',
    RCE_LANDING: APP_HASH_PATH_ROUTE + '/rce',
    PLANS_PAGE_BROWSER: APP_HASH_PATH_ROUTE + '/plans',
    SEGMENT_WHAT_FOR_STEP1: APP_HASH_PATH_ROUTE + '/what-for',
    SEGMENT_WHAT_ROLE_STEP2: APP_HASH_PATH_ROUTE + '/what-role',
    SEGMENT_ABOUT_YOU_STEP3: APP_HASH_PATH_ROUTE + '/about-you',
    TEMPLATE_REDIRECT: APP_HASH_PATH_ROUTE + '/template-redirect',
    EXPIRED_ACCOUNT: APP_HASH_PATH_ROUTE + '/expired-account',
    SEGMENTATION_INFO_REDIRECT: APP_HASH_PATH_ROUTE + '/segmentation-info-redirect',
};

export const APP_ROUTES = {
    HOMEPAGE: '/',
    HUB_DASHBOARD: '/app#',
    PLANS_SIGN_UP: '/sign-up',
    PLANS_PAGE: '/plans',
    INVITE_PAGE: '/invite',
    INVITE_PAGE_SLASH: '/invite/',
    LOGIN_PAGE: '/sign-in',
    SIGN_UP_PAGE: '/signup',
    NEW_PASSWORD: '/newPassword',
    NEXTGEN_DASHBOARD: '/class',
    INVOICE_PAYMENT_PAGE: APP_HASH_PATH_ROUTE + '/invoicepayment',
};

export const APP_EXTERNAL_URLS = {
    HUB: '/app',
    NOT_LOGGED_PLANS_PAGE: '/plans',
    SIGN_UP: '/signup',
    SIGN_IN: '/sign-in',
    LOG_OUT_URL: '/login?action=logout&marketplaceId=16409042',
    TERMS_OF_USE: '/terms-of-use',
    ACCOUNT_DASHBOARD: '/hub#account',
    PRIVACY_POLICY: '/privacy',
    EXPIRED_TRIAL: '/hub#expiredtrial',
    QUOTE: '/orders?seats=',
    RCE_EXIT_PAGE: '/api/4/rce/tokens',
    SUPPORT_PAGE: '/support',
    CLASSES_PAGE: '/class',
    DASH_PAGE: '/dash#dash',
    UPGRADE_HUB: '/hub',
};

export const WEVIDEO_PLANS = {
    FREE_PLAN: 'wv_free',
    POWER_PLAN_M: 'wv_single_1m',
    POWER_PLAN_Y: 'wv_single_12m',
    POWER_ESSENTIALS_PLAN_M: 'wv_single_plus_1m',
    POWER_ESSENTIALS_PLAN_Y: 'wv_single_plus_12m',
    UNLIMITED_PLAN_M: 'wv_unloct17_1m',
    UNLIMITED_PLAN_Y: 'wv_unloct17_12m',
    UNLIMITED_ESSENTIALS_PLAN_M: 'wv_unloct17_plus_1m',
    UNLIMITED_ESSENTIALS_PLAN_Y: 'wv_unloct17_plus_12m',
    PROFESSIONAL_PLAN_M: 'wv_bizindiv_1m',
    PROFESSIONAL_PLAN_Y: 'wv_bizindiv_12m',
    BUSINESS_PLAN_Y: 'wv_bizoct17_12m',
    BUSINESS_PLAN_M: 'wv_bizoct17_1m',
    BUSINESS_PLAN_TRIAL: 'wv_bizoct17_trial',
    FLEX_PLAN: 'wv_flex2',
    SINGLE_PLUS_PLAN: 'single_plus',
    EDUCATION_TRIAL: 'wv_edu_trial',
    PROFESSIONAL_TRIAL: 'wv_bizindiv_trial',
    EDUCATION_K12_PLAN_Y: 'wv_k12_12m',
    EDUCATION_TEACHER_PLAN_Y: 'wv_teacher_12m',
    UNLIMITED_PLAN_TRIAL: 'wv_unlimited_trial',
    INDIVIDUAL_PLAN_M: 'wv_individual_1m',
    INDIVIDUAL_PLAN_Y: 'wv_individual_12m',
};

export const ALL_ALLOWED_WEVIDEO_PLANS = [
    WEVIDEO_PLANS.FREE_PLAN,
    WEVIDEO_PLANS.POWER_PLAN_M,
    WEVIDEO_PLANS.POWER_PLAN_Y,
    WEVIDEO_PLANS.POWER_ESSENTIALS_PLAN_M,
    WEVIDEO_PLANS.POWER_ESSENTIALS_PLAN_Y,
    WEVIDEO_PLANS.UNLIMITED_PLAN_M,
    WEVIDEO_PLANS.UNLIMITED_PLAN_Y,
    WEVIDEO_PLANS.UNLIMITED_ESSENTIALS_PLAN_M,
    WEVIDEO_PLANS.UNLIMITED_ESSENTIALS_PLAN_Y,
    WEVIDEO_PLANS.PROFESSIONAL_PLAN_M,
    WEVIDEO_PLANS.PROFESSIONAL_PLAN_Y,
    WEVIDEO_PLANS.BUSINESS_PLAN_Y,
    WEVIDEO_PLANS.BUSINESS_PLAN_M,
    WEVIDEO_PLANS.BUSINESS_PLAN_TRIAL,
    WEVIDEO_PLANS.FLEX_PLAN,
    WEVIDEO_PLANS.SINGLE_PLUS_PLAN,
    WEVIDEO_PLANS.EDUCATION_TRIAL,
    WEVIDEO_PLANS.PROFESSIONAL_TRIAL,
    WEVIDEO_PLANS.EDUCATION_K12_PLAN_Y,
    WEVIDEO_PLANS.EDUCATION_TEACHER_PLAN_Y,
    WEVIDEO_PLANS.UNLIMITED_PLAN_TRIAL,
    WEVIDEO_PLANS.INDIVIDUAL_PLAN_M,
    WEVIDEO_PLANS.INDIVIDUAL_PLAN_Y,
];

export const ACCEPTED_VIDEO_EXTENSIONS = ['mp4', 'gifv', 'webm'];

export const POST_MESSAGES = {
    CLOSE_IFRAME: 'close_iframe',
    CHECKOUT_COMPLETED_SUCCESS: 'checkout_completed_success',
    CHECKOUT_COMPLETED_FAILURE: 'checkout_completed_failure',
    PAYMENT_PROCESSOR_PAYPAL: 'payment_paypal',
    PAYMENT_PROCESSOR_CC: 'payment_credit_card',
    NAVIGATE_TO_DASHBOARD: 'navigate_to_dashboard',
    CLICKED_GET_STARTED: 'clicked_get_started',
    CLICKED_PRIVACY_LINK: 'clicked_privacy_link',
    CLICKED_CONFIRM_SINGLE_EXPORT: 'clicked_confirm_single_export',
    CHECKOUT_NAVIGATION: 'cart_navigation',
    CLICKED_BACK_TO_FREE: 'clicked_back_to_free',
    EMAIL_ACTIVATION: 'email_activation',
    CLOSE_CHECKOUT_MODAL: 'close_checkout_modal',
};

export const TRACKING_CONST = {
    CHECKOUT_SUMMARY_PAGE: "website_checkout_summary"
};

export const USER_PURPOSE =  {
    K12_TEACHER: "k12_teacher",
    HIGHERED_TEACHER: "highered_teacher",
    K12_STUDENT: "k12_student",
};

export const DEFAULT_TRIAL_DURATION = 14;
export const ACTIVE_PROMO_CAMPAIGN = ["black-friday-2024"];
export const CURRENT_ACTIVE_AB_TEST = "2024_11_signup_trial";

export const RECAPTCHA_SITE_KEY_DEV = "6LeqiAYkAAAAADIYxkL0rag7DIYWDNszHLPJ9xiC";
export const RECAPTCHA_SITE_KEY_PROD = "6Lcat4ccAAAAAJgjUJZsi1SQqWiKutSY8UkDMhi5";
export const INDICATIVE_COOKIE_NAME = 'Indicative_468c47e3-fac8-45d1-b549-be10d27ca990';

export const STRIPE_KEY = {
    DEVELOPMENT: "pk_test_DWgoMENsb4ZFfh4nyU31eoRb",
    PRODUCTION: "pk_live_SKMgbKuVQANfEp9355CvfbT500t56Fi4Rl",
};

export const INVOICE_PAYMENT_ERRORS = {
    "expired_card": "The card you provided is expired. Please try again with a valid card.",
    "incorrect_cvc": "You did not enter the correct CVC code for your card. The CVC code is the three or four digit number on the back of the card.",
    "card_declined": "Your card was declined. Please reach out to your card provider for more information.",
    "processing_error": "Oops, something went wrong. Please try again",
    "incorrect_zip": "The ZIP code provided failed validation",
    "unknown": "Unknown error, please try again",
    "invalid_invoice_details": "Invalid Invoice number / Customer ID combination",
    "generic_error": "Oops, something went wrong. Please try again",
    "already_paid": "This invoice has already been paid",
};

export const BODY_CLASS_OLD_DESIGN_FONTS = "oldDesignFonts";
export const BODY_CLASS_NEW_DESIGN_FONTS = "newDesignFonts";

export const LOGIN_PAGE_TITLE = "Sign in - Log into Your Account - WeVideo";
export const SIGN_UP_PAGE_TITLE = {
    "wv_free" : "Sign Up - Create a Free Account - WeVideo",
    "wv_single_1m" : "Sign Up for a Power Plan - Monthly - WeVideo",
    "wv_single_12m" : "Sign Up for a Power Plan - Annual - WeVideo",
    "wv_unloct17_1m" : "Sign Up for a Unlimited Plan - Monthly - WeVideo",
    "wv_unloct17_12m" : "Sign Up for a Unlimited Plan - Annual - WeVideo",
    "wv_bizindiv_1m" : "Sign Up for a Professional Plan - Monthly - WeVideo",
    "wv_bizindiv_12m" : "Sign Up for a Professional Plan - Annual - WeVideo",
    "wv_bizoct17_1m" : "Sign Up for a Business Plan - Monthly - WeVideo",
    "wv_bizoct17_12m" : "Sign Up for a Business Plan - Annual - WeVideo",
    "wv_teacher_12m" : "Sign Up for a Teacher Plan - Checkout - WeVideo",
    "wv_k12_12m" : "Sign Up for a K-12 Plan - Checkout - WeVideo",
    "wv_edu_trial" : "Sign Up for a Education Trial - WeVideo",
    "default" : "Sign Up - Create a WeVideo Account",
    "wv_bizindiv_trial": "Sign Up for a Professional Trial - WeVideo",
}
