import React, { Component } from 'react';
import { Tooltip as ReactTooltip } from "react-tooltip";
import PropTypes from 'prop-types';

import './CCIcons.scss';

class CCIcons extends Component {
    render() {
        return (
            <div className="acceptedCcIcons">
                <span className={`acceptedCardIcon visa ${this.props.brand === "visa" ? "active" : ""}`}/>
                <span className={`acceptedCardIcon mastercard ${this.props.brand === "mastercard" ? "active" : ""}`}/>
                <span className={`acceptedCardIcon discover ${this.props.brand === "discover" ? "active" : ""}`}/>
                <span className={`acceptedCardIcon american express ${this.props.brand === "amex" ? "active" : ""}`}/>

                <div className="stripe-logo">
                    <span className="poweredByStripe"
                        data-tip={true}
                        data-for="tooltipStripe"
                    />
                    <ReactTooltip id="tooltipStripe" place="right" type="dark" effect="solid">
                        We use Stripe to process your payment and securely store your payment info for quick checkout on this site.
                    </ReactTooltip>
                </div>
            </div>
        );
    }
}

CCIcons.defaultProps = {
    brand: ""
};

CCIcons.propTypes = {
    brand: PropTypes.string
};

export default CCIcons;