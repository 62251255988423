import React, {Component, Fragment} from "react";

import './Invoice.scss';
import {formatPriceToFixed} from "../../helpers/GeneralHelper";

class InvoiceDetails extends Component {
    render() {
        let firstLine = true;
        return (
            <Fragment>
                {
                    <Fragment>
                        <div className="detailsTitle">Invoice Summary</div>
                        <div className="detailsSubtitle">Invoice {this.props.invoiceNumber}</div>
                        {
                            this.props.invoiceData && this.props.invoiceData.hasOwnProperty('orderLines') ? (
                                this.props.invoiceData.orderLines.map(function (line, i) {
                                    let hasBorder = false;
                                    if (firstLine === true) {
                                        firstLine = false;
                                        hasBorder = true;
                                    }
                                    return (
                                        <div key={i} className={"detailsLine" + (hasBorder === true ? " firstLine" : "")}>
                                            <div className="lineDescription">
                                                {line.description}
                                            </div>
                                            <div className="linePrice">
                                                ${formatPriceToFixed(line.amount, 2)}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : null
                        }
                        <div className="detailsTotal">
                            <div className="lineDescription">
                                Total
                            </div>
                            <div className="linePrice">
                                ${formatPriceToFixed(this.props.invoiceData.amount, 2)}
                            </div>
                        </div>
                        <div className="detailsTotal">
                            <div className="lineDescription">
                                Outstanding
                            </div>
                            <div className="linePrice">
                                ${formatPriceToFixed(this.props.invoiceData.balance, 2)}
                            </div>
                        </div>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default InvoiceDetails;