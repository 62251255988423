import React, {Component, Fragment} from "react";

import './Invoice.scss';

import ApplicationLoader from "../../components/ui/loader/ApplicationLoader";
import axios from "axios";
import {trackError} from "../../services/track/ErrorTracker";
import InvoiceStripeForm from "./InvoiceStripeForm";
import InvoiceDetails from "./InvoiceDetails";
import {getUserUserName, isAuth} from "../../helpers/AppHelper";
import {withRouter} from "react-router-dom";
import {APP_EXTERNAL_URLS, INVOICE_PAYMENT_ERRORS, RESPONSIVE_MOBILE_RESOLUTION} from "../../constants/AppGlobal";
import { getAxiosConfig } from "../../helpers/GeneralHelper";

const axiosConfig = getAxiosConfig();

class Invoice extends Component {
    paymentInProgress = false;

    constructor(props) {
        super(props);

        this.state = {
            paymentSuccess: null,
            paymentStatus: null,
            invoiceNumber: "",
            customerId: "",
            emailAddress: "",
            validInvoiceNumber: false,
            invoiceErrorMessage: "",
            paid: false,
            formFilled: false,
            invoiceData: null,
            checkoutDisabled: true,
            tokenMessage: "",
            invoiceFieldError: false,
            orderFieldError: false,
            emailFieldError: false,
        }
    }

    componentDidMount() {
        document.title = "Invoice Payment - WeVideo";
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.checkoutDisabled === true && this.state.emailAddress !== "" && this.state.invoiceNumber !== "" && this.state.customerId !== "") {
            this.setState({ checkoutDisabled: false });
        }
        if (
            this.state.checkoutDisabled === false &&
            (this.state.emailAddress === "" || this.state.invoiceNumber === "" || this.state.customerId === "")
        ) {
            this.setState({ checkoutDisabled: true });
        }
    }

    fetchInvoice = () => {
        if (this.state.checkoutDisabled === true) {
            let newState = {
                invoiceErrorMessage: "All fields are mandatory!"
            };
            if (this.state.invoiceNumber === "") {
                newState.invoiceFieldError = true;
            }
            if (this.state.dealId === "") {
                newState.orderFieldError = true;
            }
            if (this.state.emailAddress === "") {
                newState.emailFieldError = true;
            }
            this.setState(newState);

            return false;
        }
        this.handleShowLoader(true);

        let view = this;
        axios.get("/api/5/intacct/invoice/" + this.state.invoiceNumber + "/" + this.state.customerId, axiosConfig)
            .then(function (response) {
                if (response.hasOwnProperty("data")) {
                    view.setState({ invoiceData: response.data });
                }
                view.handleShowLoader(false);
            }).catch(function (error) {
                trackError(error);
                trackError("INVOICE GET ERROR");

            view.setState({
                invoiceErrorMessage: INVOICE_PAYMENT_ERRORS["invalid_invoice_details"],
                invoiceFieldError: true,
                orderFieldError: true,
            });
            view.handleShowLoader(false);
        });
    }
    getErrorMsg = (code) => {
        if (INVOICE_PAYMENT_ERRORS.hasOwnProperty(code)) {
            return INVOICE_PAYMENT_ERRORS[code];
        }

        return INVOICE_PAYMENT_ERRORS["unknown"];
    }
    handleInvoiceNumberChange = (event) => {
        this.setState({
            invoiceNumber : event.target.value,
            invoiceFieldError: false,
            invoiceErrorMessage: "",
        });
    }
    handleCustomerIdChange = (event) => {
        this.setState({
            customerId : event.target.value,
            orderFieldError: false,
            invoiceErrorMessage: "",
        })
    }
    handleEmailAddressChange = (event) => {
        this.setState({
            emailAddress: event.target.value,
            emailFieldError: false,
            invoiceErrorMessage: "",
        });
    }
    handleShowLoader = (show) => {
        this.setState({ showLoader: show });
    }
    handleBackAction = () => {
        this.setState({
            invoiceData: null,
            paymentSuccess: false,
            paymentInProgress: false
        })
    }
    hasMobileResolution = () => {
        return window.innerWidth < RESPONSIVE_MOBILE_RESOLUTION;
    }
    handleTokenResult = (response) => {
        if (response.token.id) {
            let tokenValue = response.token.id;
            // here we should send the payment request
            this.setState({tokenMessage: tokenValue});

            // sending the actual payment request to backend
            if (!this.paymentInProgress) {
                // processing the payment only if another payment is not in progress already (upgrade API call)
                this.paymentInProgress = true;

                // send the ajax call to process this invoice payment
                let submitData = {
                    token: tokenValue,
                    email: this.state.emailAddress,
                    invoiceNumber: this.state.invoiceNumber,
                    customerId: this.state.customerId,
                };
                let view = this;
                axios.post("/api/5/intacct/invoice/" + this.state.invoiceNumber, submitData, axiosConfig)
                    .then(function (response) {
                        if (response.hasOwnProperty('data')) {
                            if (response.data.success && response.data.success === true) {
                                view.setState({ paymentSuccess: true });
                            } else {
                                view.setState({
                                    invoiceErrorMessage: view.getErrorMsg(response.data.error),
                                    invoiceData: null
                                });
                            }
                        }
                        view.handleShowLoader(false);
                    }).catch(function (error) {
                        trackError(error);
                        view.setState({
                            invoiceErrorMessage: INVOICE_PAYMENT_ERRORS["generic_error"],
                            invoiceData: null,
                        });
                        view.handleShowLoader(false);
                    });
            }
        }
    }
    handleLogoutClose = () => {
        if (isAuth()) {
            window.location.href = APP_EXTERNAL_URLS.LOG_OUT_URL;
        } else {
            window.location.href = "/";
        }
    }
    render() {
        return (
            <Fragment>
                <ApplicationLoader showLoader={this.state.showLoader} />
                <div className="logoToolbar">
                    <div className="logoContainer">
                        <a href="/" target="_self" className="logo">
                            <img
                                src="https://165225.fs1.hubspotusercontent-na1.net/hubfs/165225/wevideo-2022/logo/wevideo-logo-flat.png"
                                alt="WeVideo Logo"/>
                        </a>
                    </div>
                    <div className="userInfo">
                            <span hidden={!isAuth() || this.hasMobileResolution()}
                                  className='userName'>Logged in as <strong>{getUserUserName()}</strong></span>
                        <span className="closeButton" onClick={this.handleLogoutClose}>
                            {isAuth() ? "Log out" : "CLOSE"}
                        </span>
                    </div>
                </div>

                <div className="invoicePageWrapper">
                    {
                        this.state.invoiceData !== null ? (
                            <Fragment>
                                <div className="backButton" onClick={this.handleBackAction}>
                                    <span className="icon materialIcon">arrow_back</span><span className="backButtonText">Back</span>
                                </div>
                                <div className="mainTitle">Invoice Payment</div>
                            </Fragment>
                        ) : null
                    }
                    <div className="invoicePaymentWrapper">
                        {
                            this.state.paymentSuccess === true ? (
                                <Fragment>
                                    <div className="invoiceSuccessWrapper">
                                        <div className="successSubtitle">
                                            Success
                                        </div>
                                        <div className="successDescription">
                                            <div>The payment for invoice { this.state.invoiceNumber } was successful. A receipt will be sent to { this.state.emailAddress }</div>
                                        </div>
                                    </div>
                                </Fragment>
                            ) : (
                                <div className={"invoiceContent" + (this.state.invoiceData === null ? " topMargin" : " noPadding") + (this.state.invoiceData && this.state.invoiceData.balance && this.state.invoiceData.balance === 0 ? " hidden" : "")}>
                                    {
                                        this.state.invoiceData === null ? (
                                            <Fragment>
                                                <div className="title">Invoice payment</div>
                                                <div className="subtitle">Invoice Information</div>
                                                <div className="formContainer">
                                                    <div id="invoicePaymentForm">
                                                        <label className={"label" + (this.state.invoiceFieldError === true ? " hasError" : "")}>
                                                            Invoice number (e.g. CINV001)
                                                            <input id="invoiceNumber" type="text" value={this.state.invoiceNumber}
                                                                   onChange={this.handleInvoiceNumberChange}/>
                                                        </label>

                                                        <label className={"label" + (this.state.orderFieldError === true ? " hasError" : "")}>
                                                            Customer ID (e.g. C00001)
                                                            <input id="customerId" type="text" value={this.state.customerId}
                                                                   onChange={this.handleCustomerIdChange}/>
                                                        </label>

                                                        <label className={"label" + (this.state.emailFieldError === true ? " hasError" : "")}>
                                                            Email address
                                                            <input id="emailAddress" type="email" value={this.state.emailAddress}
                                                                   onChange={this.handleEmailAddressChange} />
                                                        </label>
                                                        <div className="errorMessages">{ this.state.invoiceErrorMessage }</div>
                                                        <div className="checkoutButtonWrapper">
                                                            <button className={"textButton cta" + (this.state.checkoutDisabled ? " checkoutDisabled" : "")}
                                                                    onClick={this.fetchInvoice}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {
                                                    this.state.paymentSuccess !== true && this.state.invoiceData.balance && this.state.invoiceData.balance !== 0 ? (
                                                        <Fragment>
                                                            <div id="paymentStripeForm">
                                                                <div className="title">Payment information</div>
                                                                <InvoiceStripeForm
                                                                    message={this.state.tokenMessage}
                                                                    handleAppLoader={this.handleShowLoader}
                                                                    handleResult={this.handleTokenResult}
                                                                />
                                                            </div>
                                                        </Fragment>
                                                    ) : null
                                                }
                                                {
                                                    this.state.paymentSuccess !== true && this.state.invoiceData.hasOwnProperty('balance') && this.state.invoiceData.balance === 0 ? (
                                                        <Fragment>
                                                            <div id="paymentStripeForm">
                                                                <div className="title">Payment information</div>
                                                                <div className="detailsTitle">{ INVOICE_PAYMENT_ERRORS["already_paid"] }</div>
                                                            </div>
                                                        </Fragment>
                                                    ) : null
                                                }
                                            </Fragment>
                                        )
                                    }
                                </div>
                            )
                        }

                        {
                            this.state.invoiceData !== null ? (
                                <div className="invoiceDetails">
                                    <InvoiceDetails
                                        invoiceData={this.state.invoiceData}
                                        invoiceNumber={this.state.invoiceNumber}
                                    />
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </Fragment>);
    }

}

export default withRouter(Invoice);
