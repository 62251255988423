import React, {Component} from 'react';
import {getPaymentError} from "../../../../../helpers/CaptionHelper";
import withRouterAndRef from "../../../../withComponentWrapper/WithRouterAndRef";

import "./StripeCardForm.scss"

const createOptions = () => {
    /*
     * We have to apply the styling here since the
     * fields are loaded in an iframe. See
     * https://stripe.com/docs/stripe.js#the-elements-object
     * for options
     */
    return {
        base: {
            fontSize: '16px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: "Lato, sans-serif",
            fontWeight: "normal",
            '::placeholder': {
                color: '#cccccc'
            },
            ':disabled': {
                color: "#656565",
                backgroundColor: "#a7a7a7"
            }
        },
        invalid: {
            color: '#F26969',
        },
    };
};

class StripeInlineCardForm extends Component {
    constructor(props) {
        super(props);
        this.stripeFormButtonRef = React.createRef();

        this.state = {
            errorMessage: '',
            cardElementComplete: false,

            canSubmitForm: false,
            cardElement: null,

            cardElementInInitialState: true,
            showErrorMessage: false,

            cardBrand: "",

            cardElementIsEmpty: true,
            cvcIsEmpty: true,
            expIsEmpty: true,
            zipIsEmpty: true
        };

        //Initializing the Stripe component from the window and adding the key
        this.stripe = window.Stripe(props.stripeKey);

        this.stripeCardElement = null;
    }

    handleChange = (event) => {
        if (event.error) {
            this.setState({ errorMessage: event.error.message });
            this.setState({ showErrorMessage: true });
        } else if (this.state.errorMessage !== '') {
            this.setState({ errorMessage: '' });
            this.setState({ showErrorMessage: false });
        }
        if (typeof event !== 'undefined' && event.hasOwnProperty('complete') && event.hasOwnProperty('elementType')) {
            switch (event.elementType) {
                case 'card':
                    if (event.brand && event.brand !== this.state.cardBrand) {
                        this.handleCardBrandChange(event.brand);
                    }
                    this.setState({
                        cardElementComplete: event.complete,
                        cardElementInInitialState: false,
                    });

                    break;
                default:
                    break;
            }
        }
    };
    handleCardBrandChange = (brand) => {
        this.setState({ cardBrand: brand });
        this.props.changeCardBrand(brand);
    };
    handleFormElementsFocus = () => {
        if (this.props.isCC() === false) {
            this.props.onPayModeChange("payCC");
        } else {
            this.setState({ showErrorMessage: false });
        }
    };
    handleFormElementBlur = () => {
        if (!this.state.cardElementComplete || !this.state.cardElementInInitialState) {
            this.setState({ showErrorMessage: true });
        }
    };

    handleSubmit = (evt) => {
        evt.preventDefault();
        if (this.state.canSubmitForm === true) {
            this.stripe.createToken(this.state.cardElement)
                .then((response) => {
                    if (response.hasOwnProperty('token') && response.token.hasOwnProperty('id')) {
                        // we make sure we pass only valid answers to the handle result functions
                        this.props.handleResult(response);
                    } else {
                        // most likely some error occurred
                        if (response.error && response.error.hasOwnProperty('message')) {
                            this.paymentFail(response.error.message);
                        } else {
                            // unknown error
                            let paymentError = getPaymentError('');
                            this.paymentFail(paymentError.message);
                        }
                        this.props.handleAppLoader(false);
                        // we should also change the page to checkout summary for EU members
                    }
                });
        } else {
            console.log("Stripe.js hasn't loaded yet or form not valid!");
        }
    };
    submitStripeForm = () => {
        this.stripeFormButtonRef.current.click();
    };

    paymentFail = (error) => {
        this.setState({ errorMessage: error });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.cardElementComplete !== this.state.cardElementComplete) {
            // this.state.cardElementComplete
            this.setState({canSubmitForm: true});
            this.props.handlePaymentFormIsValid(this.state.cardElementComplete);
        }

        if(this.isCardFieldsDisabled()) {
            this.updateCardFieldsDisableStatus(true);
        } else {
            this.updateCardFieldsDisableStatus(false);
        }
    }

    componentDidMount() {
        // adding the reference to this component so that we can use the submit function in HOC
        const { childRef } = this.props;
        childRef(this);
        //initialize the stripe elements
        const elements = this.stripe.elements();

        //creating the cart number element from Stripe
        this.stripeCardElement = elements.create('card', {
            style: createOptions()
        });
        this.stripeCardElement.mount('#inline-card-element');
        this.stripeCardElement.on("change", (e) => {
            this.handleChange(e);
        });
        this.stripeCardElement.on('focus', () => {
            this.handleFormElementsFocus();
            this.setState({cardElementInInitialState: true});
        });
        this.stripeCardElement.on('blur', () => {
            this.handleFormElementBlur();
        });

        this.setState({
            cardElement: this.stripeCardElement
        });
    }
    isCardFieldsDisabled = () => {
        return !this.props.isCC() && this.props.isUpgrade;
    };

    updateCardFieldsDisableStatus = (status) => {
        if(this.stripeCardElement !== null) {
            this.stripeCardElement.update({disabled: status});
        }
    };

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    render() {
        let addDisabledClass = "";
        if(this.isCardFieldsDisabled()) {
            addDisabledClass = "disable-card-fields"
        }

        return (
            <div className="stripeCardFormWrapper selected">
                <form id="payment-inline-card-form" onSubmit={this.handleSubmit.bind(this)}>
                    <div className={"form-row" + (this.state.cardElementComplete || !this.state.showErrorMessage || this.state.cardElementInInitialState ? "" : " ccFormFieldIsEmpty")}>
                        <label>
                            <span className="formLabel">Card number</span>
                            <div id="inline-card-element" className={`field fs-hide inspectletIgnore ${addDisabledClass}`}/>
                        </label>
                    </div>
                    <div className="payment-errors" role="alert">
                        {this.state.errorMessage}
                    </div>
                    <div className="hidden payNowWrapper">
                        <button hidden={true} ref={this.stripeFormButtonRef}>Pay now</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouterAndRef(StripeInlineCardForm, {withRef: true});