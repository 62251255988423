import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {APP_EXTERNAL_URLS} from "../../../constants/AppGlobal";

class CheckoutButtonV2 extends Component {
    render() {
        if (typeof this.props.payMode !== 'undefined') {
            return (
                <Fragment>
                    {this.props.payMode === 'payPal' ? (
                        <button className={"paypalButtonV2" + (this.props.disabled ? " checkoutDisabled" : "")}
                                onClick={() => this.props.handleButtonClick()} />
                    ) : (
                        <button className={"textButton cta" + (this.props.disabled ? " checkoutDisabled" : "")}
                                onClick={() => this.props.handleButtonClick()}>{this.props.getCheckoutButtonText()}</button>
                    )}
                    <div className="upgradeTermsWrapper" hidden={this.props.isCCRequiredTrial}>
                        By purchasing, you agree to the <a href={APP_EXTERNAL_URLS.TERMS_OF_USE} rel="noopener noreferrer" target="_blank">terms of service</a> and <a href={APP_EXTERNAL_URLS.PRIVACY_POLICY} rel="noopener noreferrer" target="_blank">privacy policy</a>.
                    </div>
                </Fragment>
            )
        }
        return null;
    }
}

CheckoutButtonV2.defaultProps = {
    checkoutButtonText: '',
    payMode: '',
    handleButtonClick: () => {},
    disabled: false,
    isCCRequiredTrial: false
};

CheckoutButtonV2.propTypes = {
    payMode: PropTypes.string,
    checkoutButtonText: PropTypes.string,
    handleButtonClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isCCRequiredTrial: PropTypes.bool
};

export default CheckoutButtonV2;