import React, {Component, Fragment} from "react";

import './Invoice.scss';

import CCIcons from "../../components/ui/ccIcons/CCIcons";
import CheckoutButtonV2 from "../../components/ui/checkoutButton/CheckoutButtonV2";
import {isProduction} from "../../helpers/GeneralHelper";
import { STRIPE_KEY } from "../../constants/AppGlobal";
import InlineCreditCardForm from "../../components/checkout/checkoutSummary/checkoutSummaryDetails/stripe/InlineCreditCardForm";

class InvoiceStripeForm extends Component {
    constructor(props) {
        super(props);

        this.stripeFormRef = React.createRef();
        this.state = {
            paymentSuccess: null,
            paymentError: props.hasOwnProperty("paymentError") ? props.paymentError : null,
            paymentFormIsValid: false,
            highlightAddressErrors: false,
            brand: "",

            stripeKey: isProduction() ? STRIPE_KEY.PRODUCTION : STRIPE_KEY.DEVELOPMENT,
        }
    }
    componentDidMount() {}

    handleCheckoutButtonClick = () => {
        if (!this.isCheckoutDisabled()) {
            this.setState({ highlightAddressErrors: false });
            this.proceedWithPayment();
        } else {
            this.setState({ highlightAddressErrors: true });
        }
    }
    proceedWithPayment = () => {
        this.props.handleAppLoader(true);
        this.stripeFormRef.current.inlineFieldsFormRef.submitStripeForm();
    }
    isCheckoutDisabled = () => {
        let isDisabled = false;
        let paymentFormIsValid = this.state.paymentFormIsValid;

        if (isDisabled === false && paymentFormIsValid === false) {
            isDisabled = true;
        }

        return isDisabled;
    }
    handlePaymentFormIsValid = (status) => {
        this.setState({ paymentFormIsValid: status });
    }
    render() {
        return (
            <Fragment>
                <InlineCreditCardForm
                    ref={this.stripeFormRef}
                    handleResult={this.props.handleResult}
                    message={this.props.message}
                    stripeKey={this.state.stripeKey}
                    handlePaymentFormIsValid={this.handlePaymentFormIsValid}
                    changeCardBrand={(brand) => { this.setState({ brand: brand }) }}
                    onPayModeChange={() => {}}
                    isCC={() => {return true}}
                    handleAppLoader={this.props.handleAppLoader}
                />
                <CCIcons brand={this.state.brand} />
                <div className="checkoutButtonWrapper">
                    <CheckoutButtonV2
                        handleButtonClick={this.handleCheckoutButtonClick}
                        disabled={this.isCheckoutDisabled()}
                        payMode={"payCC"}
                        getCheckoutButtonText={() => { return "Checkout" }}
                        isCCRequiredTrial={false}
                    />
                </div>
            </Fragment>
        )
    }
}

export default InvoiceStripeForm;